import React from 'react';
import { graphql } from 'gatsby';

import Offer from '../components/offer';
import Layout from '../components/layout';
import Places from '../components/places';
import { AdUnit } from '../components/adunit';

import fixAssetUrl from '../fix-asset-url';
import formatRichText from '../format-rich-text';
import { getRandomEntries } from '../utils';

export default ({ data, pageContext }) => {
  const {
    resource,
    resources: { resources },
  } = data;
  const { slug } = pageContext;

  const moreResources = resources ? getRandomEntries(resources, 2) : null;

  return (
    <Layout
      title={resource.title}
      canonicalUrl={
        resource.canonicalUrl || `https://away.mta.info/resources/${slug}`
      }
      description={resource.description?.childMarkdownRemark?.rawMarkdownBody}
      image={fixAssetUrl(resource.heroImage?.fluid?.src)}
      mainClass="article-page"
      currentSection="resources"
      backToLink="/resources"
      backToLabel="Back to Resources"
    >
      <article
        className={`article-details ${
          resource.adUnits?.length ? '' : 'no-border'
        }`}
      >
        <div
          className={`resource-details__top ${
            resource.heroImage ? 'resource-details__top__with-image' : ''
          }`}
          style={
            resource.heroImage && {
              backgroundImage: `url(${fixAssetUrl(
                resource.heroImage.fluid?.src
              )})`,
            }
          }
        >
          <h2>{resource.title}</h2>
        </div>

        <div className="article-content resource-details__content">
          <div className="article-content-inner">
            {formatRichText(resource.body.json)}
            <Offer offer={resource.offer} />
            <Places places={resource.places} />
          </div>
        </div>

        {moreResources.length > 0 && (
          <div className="related-resources">
            <h2>More Resources</h2>
            <ul className="resource-list">
              {moreResources.map(resource => (
                <li key={resource.id}>
                  <a href={`/resources/${resource.slug}`}>
                    <img
                      src={fixAssetUrl(resource.heroImage.fluid.src)}
                      alt={resource.heroImage.title}
                    />
                    {resource.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
      <aside className="sidebar">
        <div className="sidebar-inner">
          {resource.adUnits &&
            resource.adUnits.map(ad => <AdUnit key={ad.id} ad={ad} />)}
        </div>
      </aside>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ResourceBySlug($slug: String!) {
    resource: contentfulResource(slug: { eq: $slug }) {
      id
      slug
      title
      heroImage {
        ... on ContentfulAsset {
          id
          title
          fluid(maxWidth: 1280, maxHeight: 1280, resizingBehavior: FILL) {
            src
          }
        }
      }
      body {
        json
      }
      description {
        description
      }
      holdForApproval
      places {
        ... on ContentfulPlace {
          id
          name
          location {
            lat
            lon
          }
          locationLabel
          routes {
            color
            system
            name
          }
          website
        }
      }
      offer {
        ... on ContentfulOffer {
          title
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          link
          photo {
            title
            fluid(maxWidth: 1280, maxHeight: 1280, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
      adUnits {
        ...AdUnitFragment
      }
    }

    resources: contentfulResourcesPage {
      resources {
        ... on ContentfulResource {
          id
          slug
          title
          heroImage {
            title
            fluid(maxWidth: 500, resizingBehavior: FILL) {
              src
            }
          }
        }
      }
    }
  }
`;
