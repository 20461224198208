import { adjustForTimezoneOffset } from './events';

export const getRandomEntries = (array, n) => {
  if (n > array.length) {
    n = array.length;
  }
  return array.sort(() => Math.random() - Math.random()).slice(0, n);
};

export const getRandomTransitColor = transit => {
  if (transit && transit.routes) {
    return transit.routes[Math.floor(Math.random() * transit.routes.length)]
      .color;
  }
  return '#EE6B34'; // mta orange
};

export const getFirstTransitColor = transit => {
  if (transit && transit.routes) {
    return transit.routes[0].color;
  }
  return '#EE6B34'; // mta orange
};

export const copyToClipboard = text => {
  if (typeof navigator !== 'undefined') {
    if (!navigator.clipboard) {
      fallbackCopyToClipboard(text);
      return;
    }

    navigator.clipboard.writeText(text).catch(e => {
      console.error(`Oops, unable to copy ${text}`, e);
    });
  }
};

const fallbackCopyToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (e) {
    console.error(`Oops, unable to copy ${text}`, e);
  }

  document.body.removeChild(textArea);
};

export const getRandomHeroImageByCategory = (
  categories,
  homepageCategories
) => {
  // filter categories first, since it may contain non-homepage categories
  const homepageSlugs = homepageCategories.map(c => c.slug);

  // if no category is specified in preview mode, return the first available
  // hero image as a placeholder.
  if (!categories) {
    return homepageCategories[0].featuredImage;
  }

  const filteredCategories = categories.filter(c =>
    homepageSlugs.includes(c.slug)
  );

  // if a category is used that's not in the homepage categories
  // there won't be any `filteredCategories`
  // use a random image from the existing categories
  if (!filteredCategories.length) {
    return categories[Math.floor(Math.random() * categories.length)]
      .featuredImage;
  }

  const randomSlug =
    filteredCategories[Math.floor(Math.random() * filteredCategories.length)]
      .slug;
  const homepageCategoryBySlug = homepageCategories.find(
    ({ slug }) => slug === randomSlug
  );
  return homepageCategoryBySlug.featuredImage;
};

// shamelessly adapted from https://stackoverflow.com/a/2627493
export const calculateDaysLeft = end => {
  // hours * minutes * seconds * milliseconds
  const oneDay = 24 * 60 * 60 * 1000;

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const today = adjustForTimezoneOffset(todayDate);
  const myEndDate = new Date(end);
  const endDate = adjustForTimezoneOffset(myEndDate);
  // no Math.abs on the initial calc
  // negative number === expired
  // 0 === expires today
  const diffDays = Math.round((endDate - today) / oneDay);
  return diffDays;
};
