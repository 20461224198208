import React from 'react';
import ContentfulImage from './contentful-image';

export default ({ offer }) => {
  if (!offer) {
    return null;
  }

  return (
    <div className="offer">
      <div className="offer__details">
        <h2 className="offer__title">{offer.title}</h2>
        {offer.description && (
          <p className="offer__description">
            {offer.description.childMarkdownRemark.rawMarkdownBody}
          </p>
        )}
        <p className="offer__link">
          <a className="btn" href={offer.link} target="_blank" rel="noreferrer">
            Learn More
          </a>
        </p>
      </div>
      <div className="offer__photo">
        <ContentfulImage image={offer.photo} />
      </div>
    </div>
  );
};
