const prependLeadingZero = value => {
  if (value < 10) {
    return '0' + value;
  }
  return value;
};

export const adjustForTimezoneOffset = date => {
  date.setUTCHours(date.getTimezoneOffset() / 60);
  return date;
};

const getFirstDateOfThisMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = prependLeadingZero(now.getMonth() + 1);
  const firstDate = new Date(`${year}-${month}-01`);
  firstDate.setUTCHours(firstDate.getTimezoneOffset() / 60);
  return firstDate;
};

// given a Date object, it returns a string formatted as a short date (i.e. "Feb 11")
const dateToShortDate = date => {
  const formatter = new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: 'numeric',
  });
  return formatter.format(date);
};

export const getDisplayDate = (start, end) => {
  const today = adjustForTimezoneOffset(new Date());
  const startDate = adjustForTimezoneOffset(new Date(start));
  const endDate = adjustForTimezoneOffset(new Date(end));

  let displayDate = startDate;
  if (today > startDate && today < endDate) {
    displayDate = today;
  } else if (today > endDate) {
    displayDate = endDate;
  }

  return dateToShortDate(displayDate);
};

export const getStartingPrice = pricingTable => {
  if (pricingTable) {
    const prices = [];
    pricingTable.forEach(price => {
      let amount = price.split('|')[1];
      amount = amount ? amount.trim() : null;
      if (amount) {
        prices.push(Number(amount));
      }
    });
    const sorted = prices.sort((a, b) => {
      return a - b;
    });
    return sorted[0];
  }
  return null;
};

// adapted from: https://stackoverflow.com/questions/30464628/javascript-get-all-months-between-two-dates/55925332#55925332
export const getMonthsOfOcurrence = (fromDate, toDate) => {
  fromDate = adjustForTimezoneOffset(fromDate);
  toDate = adjustForTimezoneOffset(toDate);

  const fromYear = fromDate.getFullYear();
  const fromMonth = fromDate.getMonth();
  const toYear = toDate.getFullYear();
  const toMonth = toDate.getMonth();
  const months = [];
  for (let year = fromYear; year <= toYear; year++) {
    let month = year === fromYear ? fromMonth : 0;
    const monthLimit = year === toYear ? toMonth : 11;
    for (; month <= monthLimit; month++) {
      const monthFormatted = prependLeadingZero(month + 1);
      months.push(`${year}-${monthFormatted}-01`);
    }
  }
  return months;
};

// given a datestring, it returns the name of the month
export const datestringToMonth = datestring => {
  let date = new Date(datestring);
  date = adjustForTimezoneOffset(date);
  const formatter = new Intl.DateTimeFormat('en-us', { month: 'long' });
  const dateParts = formatter.formatToParts(date);
  return dateParts[0].value;
};

// given a datestring, determine if it falls before the 1st of the current month
export const isBeforeCurrentMonth = datestring => {
  const thisMonth = getFirstDateOfThisMonth();
  let date = new Date(datestring);
  date = adjustForTimezoneOffset(date);
  // return date < thisMonth;
};
