import React, { useEffect } from 'react';
import Pin from './pin';

export default ({ places }) => {
  if (!places?.length) {
    return null;
  }

  const mapMarkers = places.map(
    ({ id, name, location: { lat, lon }, routes }) => ({
      id,
      name,
      lat,
      lon,
      color: (routes || [])[0]?.color,
    })
  );

  useEffect(() => {
    if (typeof window === 'undefined' || !window.mapboxgl) {
      return;
    }

    window.mapboxgl.accessToken =
      'pk.eyJ1Ijoiam9obmhvbGR1biIsImEiOiJjajR1bWU4bTUwZXU2MnFwMjg2YWs3c2RmIn0.RQKA6FHi5GzqnsFrNJEQXA';

    const lats = mapMarkers.map(({ lat }) => lat).sort();
    const lons = mapMarkers.map(({ lon }) => lon).sort();
    const averageLat = lats.reduce((a, b) => a + b) / lats.length;
    const averageLon = lons.reduce((a, b) => a + b) / lons.length;

    const map = new window.mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [averageLon, averageLat],
      zoom: 9,
    });

    map.addControl(
      new window.mapboxgl.NavigationControl({ showCompass: false })
    );

    map.fitBounds(
      [
        [lons[0], lats[0]],
        [lons[lons.length - 1], lats[lats.length - 1]],
      ],
      {
        padding: 48,
        maxZoom: 13,
      }
    );

    mapMarkers.forEach(({ id, name, lat, lon, color }, index) => {
      const marker = document.createElement('div');
      marker.innerHTML = `
         <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="40" viewBox="0 0 24 40" className="place-marker__image">
          <path
            opacity=".5"
            d="M12 40a.98.98 0 01-.78-.38 63.8 63.8 0 01-5.6-8.78A56.959 56.959 0 011.76 22 32.78 32.78 0 010 12a12 12 0 1124 0 32.78 32.78 0 01-1.76 10 56.951 56.951 0 01-3.86 8.82 63.8 63.8 0 01-5.6 8.78.98.98 0 01-.78.4z"
            fill="${color || '#5790C3'}"
          />
          <path
            d="M8.72 4.153A8.327 8.327 0 0112 3.478c1.126 0 2.24.23 3.28.675a8.56 8.56 0 012.781 1.921 8.889 8.889 0 011.858 2.875 9.125 9.125 0 01.653 3.392 36.792 36.792 0 01-4.595 15.83A63.574 63.574 0 0112 34.782a61.632 61.632 0 01-3.96-6.487A36.794 36.794 0 013.43 12.34c0-2.35.903-4.605 2.51-6.267A8.558 8.558 0 018.72 4.153z"
            fill="#000"
          />
        </svg>
        <span class="place-marker__label">
          ${index + 1}
        </span>
      `;
      marker.classList.add('place-marker');
      marker.addEventListener('click', e => {
        document
          .querySelectorAll('[data-place-id].selected, .place-marker.selected')
          .forEach(place => {
            place.classList.remove('selected');
          });

        marker.classList.add('selected');

        document
          .querySelector(`[data-place-id="${id}"]`)
          .classList.add('selected');
      });

      new window.mapboxgl.Marker(marker).setLngLat([lon, lat]).addTo(map);
    });
  }, [mapMarkers]);

  const getPlaceNames = place => {
    if (place.routes?.length) {
      return place.routes
        .map(route => {
          if (route.system === 'Subway') {
            return (
              <img
                width="16"
                height="16"
                src={`/lines/${route.name.toLowerCase()}.svg`}
                alt={route.name}
                className="route-bullet"
              />
            );
          }

          return (
            <React.Fragment>
              {route.system}{' '}
              <span
                className="route-marker"
                style={{ background: route.color }}
              />
            </React.Fragment>
          );
        })
        .concat([' ', place.name]);
    }

    return <h2>{place.name}</h2>;
  };

  return (
    <React.Fragment>
      <h2>Places Featured</h2>
      <div className="places">
        <div
          className="places__map"
          id="map"
          data-map-markers={JSON.stringify(mapMarkers)}
        />
        <ol className="places__list">
          {places.map((place, index) => (
            <li key={place.id} data-place-id={place.id}>
              <Pin color={(place.routes || [])[0]?.color} label={index + 1} />
              {getPlaceNames(place)}
              {place.locationLabel && <p>{place.locationLabel}</p>}
              {place.website && (
                <p>
                  <a href={place.website} target="_blank" rel="noreferrer">
                    {place.website.replace(/^https?:\/\/|\/$/gi, '')}
                  </a>
                </p>
              )}
            </li>
          ))}
        </ol>
      </div>
    </React.Fragment>
  );
};
