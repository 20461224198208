import React from 'react';

export default ({ label, color = '#5790C3' }) => (
  <div className="place-marker">
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="40"
      viewBox="0 0 24 40"
      className="place-marker__image"
    >
      <path
        opacity=".5"
        d="M12 40a.98.98 0 01-.78-.38 63.8 63.8 0 01-5.6-8.78A56.959 56.959 0 011.76 22 32.78 32.78 0 010 12a12 12 0 1124 0 32.78 32.78 0 01-1.76 10 56.951 56.951 0 01-3.86 8.82 63.8 63.8 0 01-5.6 8.78.98.98 0 01-.78.4z"
        fill={color}
      />
      <path
        d="M8.72 4.153A8.327 8.327 0 0112 3.478c1.126 0 2.24.23 3.28.675a8.56 8.56 0 012.781 1.921 8.889 8.889 0 011.858 2.875 9.125 9.125 0 01.653 3.392 36.792 36.792 0 01-4.595 15.83A63.574 63.574 0 0112 34.782a61.632 61.632 0 01-3.96-6.487A36.794 36.794 0 013.43 12.34c0-2.35.903-4.605 2.51-6.267A8.558 8.558 0 018.72 4.153z"
        fill="#000"
      />
    </svg>
    <span class="place-marker__label">{label}</span>
  </div>
);
